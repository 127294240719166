import React from 'react';
import CtaBlock from '../../components/components-group-one/CtaBlock';
import Footer from '../../components/footer';
import HtmlHead from '../../components/html-head';
import Navbar from '../../components/navbar';
import { PageData } from '../../types';
import ResourceHero from '../../components/resources-components/resource-hero';
import CaseStudy from '../../components/resources-components/case-study';
import everbridge from '../../assets/resources/everbridge.png';
import renee from '../../assets/resources/Renee baltine.png';
import { graphql } from 'gatsby';

const EverBridge: React.FC<PageData> = resp => {
    const seoData = resp?.data?.allContentfulPageSeoData?.edges[0]?.node;

    const resourceData = {
        formName : 'everbridge',
        companyLogo: everbridge,
        title: 'Earning High CSAT with The Right Answers at the Right Time',
        author: {
            image: renee,
            name: '<span>Renee</span> Batine',
            title: 'Sr. Dir. Global Customer Support',
            quote: `Our customer satisfaction rates have been 99% ever since we put AptEdge in use.`
        },
        stats: [
            {
                percentage: '99%',
                title: 'Achieved CSAT'
            }
        ],
        pdf: "/blog/everbridge-achieves-99-customer-satisfaction-with-help-from-aptedge",
        ctaTitle: "Read Case Study",
        count: [],
        countColor: '#E75500',
        isdownload: false
    };

    const caseStudy = [
        {
            title: 'About',
            desc: `Everbridge is at the forefront of Critical Event Management, offering a comprehensive platform designed 
                    to help organizations effectively manage the entire lifecycle of critical events. These events range 
                    from natural disasters to cyber attacks, ensuring that businesses can respond quickly and efficiently 
                    to minimize impact. The company's services are crucial for operational resilience, safety, and security, 
                    serving a wide range of industries globally. Everbridge's platform integrates state-of-the-art technology 
                    to provide real-time monitoring, communication, and response coordination. This enables organizations 
                    to not only anticipate and prepare for critical events but also to manage and recover from them more effectively.`
        },
        {
            title: 'Solution',
            desc: `To overcome these challenges, Everbridge collaborated with AptEdge, leveraging their expertise to enhance the 
                    efficiency and effectiveness of their customer support operations. AptEdge's solution was seamlessly integrated into 
                    Everbridge's existing ticketing system, equipping support technicians with instant access to a vast repository of 
                    information and insights. This integration was designed to be intuitive, ensuring that technicians could find the information 
                    they needed without leaving their workflow. The solution provided by AptEdge was not just about information access but 
                    also about streamlining the support process, enabling technicians to resolve issues faster and with greater accuracy.`
        },
        {
            title: 'Challenge',
            desc: `Everbridge faced a challenge in maintaining high levels of customer support amidst growing demand for their critical
                    event management solutions. The primary hurdle was ensuring that their support technicians had swift and easy access to 
                    the right information to address customer issues effectively. This was crucial for reducing the time it took to resolve 
                    tickets and minimizing the need for escalations. The challenge was compounded by the complexity of the inquiries and the 
                    critical nature of the services Everbridge provides, which meant that delays in resolution could have significant 
                    repercussions for their clients.`
        },
        {
            title: 'Results',
            desc: `The partnership between Everbridge and AptEdge yielded remarkable results, significantly impacting customer satisfaction 
                    and operational efficiency. By implementing AptEdge's solution, Everbridge was able to achieve a 99% customer satisfaction rating, 
                    a testament to the effectiveness of the solution in enhancing support services. The integration led to a tangible reduction in ticket 
                    resolution times and a decrease in escalations, as support technicians were able to access necessary information more swiftly and 
                    resolve issues more effectively. These improvements not only benefited Everbridge's clients by ensuring quicker resolutions to their 
                    critical issues but also enhanced the overall efficiency of Everbridge's support operations.`
        },
    ];

    return (
        <>
            <HtmlHead
                title={seoData?.title}
                metaDescription={seoData?.metaDescription?.metaDescription}
                canonicalLink={seoData?.canonicalLink}
                openGraphImage={seoData?.openGraphImage?.file?.url}
            />
            <Navbar />
            <ResourceHero data={resourceData} />
            <CaseStudy data={caseStudy} />
            <CtaBlock ctaTitle="Start Today!" />
            <Footer />
        </>
    );
};

export default EverBridge;

export const query = graphql`
    query EverBridgeQuery {
        allContentfulPageSeoData(filter: { pagePath: { eq: "/case-study/ever-bridge" } }) {
            edges {
                node {
                    title
                    canonicalLink
                    metaDescription {
                        metaDescription
                    }
                    openGraphImage {
                        file {
                            url
                        }
                    }
                }
            }
        }
    }
`;